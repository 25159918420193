import IndexPages__GenericIndexPageController from "./generic_index_page_controller"
import { updateSelect2 } from 'shared/select2_init';
// Connects to data-controller="index-pages--invoice-index-page"
export default class extends IndexPages__GenericIndexPageController {
  selectAutocompleteCallback(input) {
    $('#index-page-filters__input__contactType').val('_')
    updateSelect2($('#index-page-filters__input__contactType'))
    $('#index-page-filters__input__contactType').parent().hide()
  }

  deselectAutocompleteCallback(input) {
    $('#index-page-filters__input__contactType').parent().show()
  }
}
