import writeXlsxFile from 'write-excel-file'

export class ExportXlsx {
  constructor(table_id, options, fileName) {
    this.table_container = $(`#${table_id}`)
    this.options = options
    this.fileName = fileName
    this.types = {
      "String": String,
      "Boolean": Boolean,
      "Number": String,
      "Date": Date,
      "Integer": Number
    }
  }

  async render() {
    this.datas = []
    this.sheetNames = []
    let tables = this.table_container.find('.dataTables_scrollBody').length ? this.table_container.find('.dataTables_scrollBody table') : this.table_container.find('table')
    $.each(tables, (i, table) => {
      if(!$(table).data().noExport) {
        this.sheet_datas = []
        this.formats = []
        this.sheetNames.push($(table).data().sheetName)
        this.generateHeader($(table))
        
        $.each($(table).find('tbody tr'), (i, row) => {
          this.addRow(row, 'td')
        }) 
        if($(table).find('tfoot').length) {
          $.each($(table).find('tfoot tr'), (i, row) => {
            this.addRow(row, 'th')
          }) 
        }
        this.datas.push(this.sheet_datas)          
      }
   
    })
    await writeXlsxFile(this.datas, {fileName: `${this.fileName}.xlsx`, sheets: this.sheetNames})
  }

  addRow(row, cell_tag) {
    let row_data = []
    $.each($(row).find(cell_tag), (i, cell) => {
      let value
      if($(cell).data().value) {
        value = $(cell).data().value.toString()
      } else if($(cell).data().order) {
        value = $(cell).data().order.toString()
      } else {
        value = $(cell).text().trim()
      }
      let cell_data = {value: value}
      cell_data.type = this.formats[i].type
      if(this.formats[i].format) {
        cell_data.format = this.formats[i].format
      }
      if(cell_tag == 'th') {
        cell_data.fontWeight = 'bold'
      }
      row_data.push(cell_data)
    })
    this.sheet_datas.push(row_data)
  }

  generateHeader(table) {
    $.each(table.find('thead tr'), (i, row) => {
      let row_data = []
      $.each($(row).find('th'), (j, cell) => {
        if($(row).data().formattingRow) {
          if(!$(cell).data().columnType) {
            this.formats.push({type: String})
          } else if($(cell).data().columnType == 'Currency') {
            this.formats.push({type: "Formula", format: '# ##0.00 [$€-40C];-# ##0.00 [$€-40C]'})
          } else if($(cell).data().columnType == 'Number') {
            this.formats.push({type: "Formula", format: '0.0##'})
          } else if($(cell).data().columnType == 'Integer') {
            this.formats.push({type: "Formula", format: "0"})
          } else {
            this.formats.push({type: this.types[$(cell).data().columnType]})
          }          
        }
        let cell_data = {
          value: $(cell).data().displayExport ? $(cell).data().displayExport : $(cell).text().trim(),
          fontWeight: 'bold'
        }
        if($(cell).data().align) {
          cell_data.align = $(cell).data().align
        } 
        if($(cell).attr('colspan')) {
          cell_data.span = parseInt($(cell).attr('colspan'))
        }
        row_data.push(cell_data)
        if($(cell).attr('colspan')) {
          [...Array(parseInt($(cell).attr('colspan')) - 1)].forEach((_, i) => {
            row_data.push(null)
          });
        }
         
      })
      this.sheet_datas.push(row_data)      
    }) 
  }
}