import { Controller } from "@hotwired/stimulus"
import { TurboToporderTable } from 'shared/turbo_toporder_table';
// Connects to data-controller="index-pages--generic-index-table"
export default class extends Controller {
  connect() {
    if($(this.element).find('table').length > 0) {
      new TurboToporderTable(
        $(this.element).find('table'),
        undefined,
        undefined,
        $(`#${$(this.element).data().mainController}`).get(0).controller
      )      
      $('.menu_export_xlsx').show()
    } else {
      $('.menu_export_xlsx').hide()
    }

  }
}
