import { Controller } from "@hotwired/stimulus"
import { TurboToporderTable } from 'shared/turbo_toporder_table';

// Connects to data-controller="back--statistics--v2--statistics-employees-table"
export default class extends Controller {
  connect() {
    new TurboToporderTable(
      $(this.element).find('table'),
      undefined,
      'autosort',
    )
  }
}
