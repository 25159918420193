import { Controller } from "@hotwired/stimulus"
import { loadDatepicker } from 'shared/load_datepicker';
// Connects to data-controller="back--scale-service-tickets-prepare-delete"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      loadDatepicker($(this.element).find('.datepicke'))
    }, 300)
  }
}
