import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--commercial-action-filter"
export default class extends Controller {
  static targets = ['dateRange', 'dateRangeAccomplishedAt']
  connect() {
    this.element['controller'] = this
    this.accomplishedAtTouched = false
    let datas = $(this.element).data()
    let ranges = {
      "Aujourd'hui": [moment(datas.today.split('-')[0], 'DD/MM/YYYY'), moment(datas.today.split('-')[1], 'DD/MM/YYYY')],
      "Hier": [moment(datas.yesterday.split('-')[0], 'DD/MM/YYYY'), moment(datas.yesterday.split('-')[1], 'DD/MM/YYYY')],
      "Demain": [moment(datas.tomorrow.split('-')[0], 'DD/MM/YYYY'), moment(datas.tomorrow.split('-')[1], 'DD/MM/YYYY')],
      "La semaine en cours": [moment(datas.current.split('-')[0], 'DD/MM/YYYY'), moment(datas.current.split('-')[1], 'DD/MM/YYYY')],
      "La semaine précédente": [moment(datas.prev.split('-')[0], 'DD/MM/YYYY'), moment(datas.prev.split('-')[1], 'DD/MM/YYYY')],
      "La semaine suivante": [moment(datas.next.split('-')[0], 'DD/MM/YYYY'), moment(datas.next.split('-')[1], 'DD/MM/YYYY')],
      "Le mois en cours": [moment(datas.currentMonth.split('-')[0], 'DD/MM/YYYY'), moment(datas.currentMonth.split('-')[1], 'DD/MM/YYYY')],
      "Le mois précédent": [moment(datas.prevMonth.split('-')[0], 'DD/MM/YYYY'), moment(datas.prevMonth.split('-')[1], 'DD/MM/YYYY')],      
      "Le mois suivant": [moment(datas.nextMonth.split('-')[0], 'DD/MM/YYYY'), moment(datas.nextMonth.split('-')[1], 'DD/MM/YYYY')],
      "L'année en cours": [moment(datas.year.split('-')[0], 'DD/MM/YYYY'), moment(datas.year.split('-')[1], 'DD/MM/YYYY')],
      "De tout temps": [moment(datas.all.split('-')[0], 'DD/MM/YYYY'), moment(datas.all.split('-')[1], 'DD/MM/YYYY')],
    }
    $(this.dateRangeTarget).daterangepicker({
      parentEl: ".filter_card",
      ranges: ranges,
      startDate: $(this.dateRangeTarget).data().startDate.split('/').join('-'),
      endDate: $(this.dateRangeTarget).data().endDate.split('/').join('-')
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $(this.dateRangeTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeTarget).find('span').text(picker)
      }
      $(this.dateRangeTarget).data('startDate', start.format('DD-MM-YYYY'))
      $(this.dateRangeTarget).data('endDate', end.format('DD-MM-YYYY'))
    })
    $(this.dateRangeAccomplishedAtTarget).daterangepicker({
      parentEl: ".filter_card",
      ranges: ranges,
      startDate: $(this.dateRangeAccomplishedAtTarget).data().startDate.split('/').join('-'),
      endDate: $(this.dateRangeAccomplishedAtTarget).data().endDate.split('/').join('-')
    }, (start, end, picker) => {
      this.accomplishedAtTouched = true
      if(picker == "Personnalisé") {
        $(this.dateRangeAccomplishedAtTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeAccomplishedAtTarget).find('span').text(picker)
      }
      $(this.dateRangeAccomplishedAtTarget).data('startDate', start.format('DD-MM-YYYY'))
      $(this.dateRangeAccomplishedAtTarget).data('endDate', end.format('DD-MM-YYYY'))
    })    

    this.checkStatuses()

    $(this.element).find('#prospect_statuses').on("change", (e) => {
      this.checkStatuses()
    })  
    $(this.element).find('#prospect_statuses').on("select2:unselecting", (e) => {
      if($('#prospect_statuses').val().length == 1) {
        e.preventDefault()
      }
    })    
  }

  checkStatuses() {
    $(this.element).find('.prospect_index_commercial_steps_filter').toggle(!$('#prospect_statuses').val().includes('signed'))
  }

  filter() {
    var params = []

    if($('#action_type').val() != '_' && $('#action_type').val() !== null) {
      params.push(`commercial_action_type_id=${parseFloat($('#action_type').val())}`)
    }

    if($('#action_toporder_employee').length && $('#action_toporder_employee').val() != '_' && $('#action_toporder_employee').val() !== null) {
      params.push(`toporder_employee_id=${$('#action_toporder_employee').val()}`)
    }

    if($('#action_status').val() != '_' && $('#action_status').val() !== null) {
      if($('#action_status').val() == 'Accomplie'){
        params.push(`is_accomplished=true&is_archived=false`)
      }
      if($('#action_status').val() == 'Archivée'){
        params.push(`is_archived=true`)
      }
      if($('#action_status').val() == 'A réaliser'){
        params.push(`is_archived=false&is_accomplished=false`)
      }
    }

    params.push(`startDate=${$(this.dateRangeTarget).data().startDate.split('/').join('-')}&endDate=${$(this.dateRangeTarget).data().endDate.split('/').join('-')}`)

    if($(this.dateRangeAccomplishedAtTarget).data().startDate && this.accomplishedAtTouched) {
      params.push(`startDateAccomplishedAtStartDate=${$(this.dateRangeAccomplishedAtTarget).data().startDate.split('/').join('-')}`)
      params.push(`startDateAccomplishedAtEndDate=${$(this.dateRangeAccomplishedAtTarget).data().endDate.split('/').join('-')}`)
    }
    if($('#prospect_api_business_category_uuid').length && $('#prospect_api_business_category_uuid').val() != '_') {
      params.push(`prospect_api_business_category_uuid=${$('#prospect_api_business_category_uuid').val()}`)
    }

    if($('#prospect_filter_commercial_step').length && $('#prospect_filter_commercial_step').val() != '_') {
      params.push(`prospect_commercial_step_id=${$('#prospect_filter_commercial_step').val()}`)
    }

    if($('#prospect_filter_prospect_inbound_type').length && $('#prospect_filter_prospect_inbound_type').val() != '_') {
      params.push(`prospect_inbound_type_id=${$('#prospect_filter_prospect_inbound_type').val()}`)
    }

    if($('#prospect_origin').val() != '_' && $('#prospect_origin').val() !== null) {
      params.push(`prospect_origin_id=${$('#prospect_origin').val()}`)
    }

    if($('#prospect_filter_zip_code').val().length) {
      params.push(`zip_code=${$('#prospect_filter_zip_code').val()}`)
    }

    if($('#actions_signed').prop('checked')) {
      params.push(`include_signed=true`)
    }    

    let url_params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    if(!url_params['sort']) {
      url_params['sort'] = 'sort_up'
      url_params['field'] = 'date'
    }

    params.push(`sort=${url_params['sort']}`)
    params.push(`field=${url_params['field']}`)
    params.push(`statuses=${$('#prospect_statuses').val().join('_')}`)

    let url = params.length ? `/admin/commercial_actions?${params.join('&')}` : `/admin/commercial_actions`
    ajaxTurboStream(url, true)
  }
}
