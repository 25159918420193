import { Controller } from "@hotwired/stimulus"
import { TurboToporderTable } from 'shared/turbo_toporder_table';
// Connects to data-controller="back--statistics--v2--statistics-products-table"
export default class extends Controller {
  connect() {
    if($(this.element).data().cacheKey) {
      let url = `/back/statistics/v2/products/rebuild_cache?`
      let data = $(this.element).data()
      url += `cache_key=${data.cacheKey}`
      url += `&shop_id=${data.shopId}`
      url += `&page=${data.page}`
      url += `&group_by_day=${data.groupByDay}`
      new TurboToporderTable($(this.element), url, 'turbo')   
    }
  }
}
