export function defaultMomentRanges(moment) {
  return {
    all_time: {
      label: "Toutes les dates",
      range: [moment("2020-01-01"), moment().add(1, 'year')]
    },
    today: {
      label: "Aujourd'hui",
      range: [moment(), moment()]
    },
    yesterday: {
      label: "Hier",
      range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    },
    prev_week: {
      label: "Les 7 derniers jours",
      range: [moment().subtract(6, 'days'), moment()]
    },
    prev_month: {
      label: "Le mois précédent",
      range: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
    },
    current_month: {
      label: "Le mois en cours",
      range: [moment().startOf('month'), moment().endOf('month')]
    },
    thirty_days: {
      label: "Les 30 derniers jours",
      range: [moment().subtract(29, 'days'), moment()]
    },
    current_year: {
      label: "L'année en cours",
      range: [moment().startOf('year'), moment().endOf('year')]
    },
    prev_year: {
      label: "L'année précédente",
      range: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')]
    }
  }
}