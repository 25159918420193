import { Controller } from "@hotwired/stimulus"
import { initSelect2, updateSelect2 } from 'shared/select2_init';

// Connects to data-controller="back--remote-service-items-new"
export default class extends Controller {
  connect() {
    this.formatter = new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'})
    $(this.element).find('#remote-service-items__discount-select').on('select2:select', (e) => {
      this.discountChanged()
    })
  }

  toggleOrder(e) {
    if($(this.element).find('.remote-service-items__local-order.active').length == 50 && !$(e.currentTarget).hasClass('active')) {
      return
    }
    $(e.currentTarget).toggleClass('active')
    if($(this.element).find('.remote-service-items__local-order.active').length == 0) {
      $('#remote-service-items__checkout').removeClass('active')
    } else {
      $('#remote-service-items__checkout').addClass('active')
      this.showCheckout()
    }
  }

  discountChanged() {
    $('#remote-service-items__discount-custom-container').toggle(
      $('#remote-service-items__discount-select').val() == 'custom_discount'
    )
    this.applyDiscount()
  }

  showCheckout() {
    initSelect2($('#remote-service-items__discount-select'))
    $('#remote-service-items__total-basket').text(this.formatter.format(this.totalOrders()))
    this.checkCustomDiscountValue()
  }

  applyDiscount() {
    let total = this.totalOrders()
    let discount_val = $('#remote-service-items__discount-select').val()
    let total_discount = 0
    let applied_discount = {value: 0, type: 0}
    if(discount_val != 'no_discount') {
      if(discount_val == 'custom_discount') {
        applied_discount = {
          value: parseFloat($('.remote-service-items__discount-custom-discount-value input').val()),
          type: parseInt($('.remote-service-items__discount-custom-discount-type.active input').val())
        }
      } else {
        applied_discount = $(this.element).data().discounts[discount_val]
      }
    }

    if(applied_discount.value != 0) {
      if(applied_discount.type == 0) {
        total_discount = ((total * applied_discount.value) / 100).toFixed(2)
      } else {
        total_discount = applied_discount.value
      }
    }
    total = total - total_discount
    if(total < 0) {
      total = 0
      total_discount = this.totalOrders()
    }
    $('#remote-service-items__total-discounts').text(this.formatter.format(total_discount))
    $('#remote-service-items__total').text(this.formatter.format(total))
  }

  totalOrders() {
    let sum = 0;
    $.each($('.remote-service-items__local-order.active'), (i, e) => {
      sum += parseFloat($(e).data().price)
    })
    return sum
  }

  toggleCustomDiscountType(e) {
    setTimeout(()=> {
      $('.remote-service-items__discount-custom-discount-value .input-group-text').text(
        $('.remote-service-items__discount-custom-discount-type.active input').val() == '0' ? "%" : "€"
      )
      this.checkCustomDiscountValue()
    }, 200)
  }

  checkCustomDiscountValue() {
    if($('.remote-service-items__local-order.active').map((i, e) => {return $(e).data().hasDiscount}).get().includes(true)) {
      $('#remote-service-items__discount-select').val('no_discount')
      $('#remote-service-items__checkout').addClass('no-discount')
      $('#remote-service-items__discount-custom-container').hide()
    } else {
      $('#remote-service-items__checkout').removeClass('no-discount')
    }
    updateSelect2($('#remote-service-items__discount-select'))
    let max = $('.remote-service-items__discount-custom-discount-type.active input').val() == '0' ? 100 : this.totalOrders()
    if(parseFloat($('.remote-service-items__discount-custom-discount-value input').val()) > max) {
      $('.remote-service-items__discount-custom-discount-value input').val(max)
    }
    this.applyDiscount()
  }

  submit() {
    $('#loading_app').addClass('d-flex')
    let discount
    if($('#remote-service-items__discount-select').val() != 'no_discount') {
      if($('#remote-service-items__discount-select').val() == 'custom_discount') {
        discount = {
          value: parseFloat($('.remote-service-items__discount-custom-discount-value input').val()),
          type: parseInt($('.remote-service-items__discount-custom-discount-type.active input').val())
        }
      } else {
        discount = {id: $('#remote-service-items__discount-select').val()}
      }
    }
    $.ajax({
      type: 'POST',
      url: '/back/remote_service_items',
      data: {
        discount: discount,
        orderIds: $('.remote-service-items__local-order.active').map((i, e) => {return $(e).data().uuid}).get(),
        shopId: $(this.element).data().shopId,
        clientId: $(this.element).data().clientId
      }
    }).done(data => {
      $('#loading_app').removeClass('d-flex')
      if(data.code == 409) {
        $.each($('.remote-service-items__local-order.active'), (i, e) => {
          if(data.orderIds.includes($(e).data().uuid)) {
            $(e).addClass('has_issue')
          }
        })
      } else {
        this.handleSuccess(data.remote_service_item_id)
        
      }
    })
  }

  handleSuccess(uuid) {
    $('.modal-dialog').removeClass('modal-xl')
    $('#modal_rails_5 .modal-body').html(`
      <div class="loading_modal">
        <p>La demande a été envoyée à la caisse.</p>
        <p class='mb-0'>Cette fenêtre se mettra à jour quand une réponse aura été obtenue.</p>
        <div class="loading_circle"></div>
      </div>
    `)
    let interval_counts = 0
    this.interval = setInterval(() => {
      interval_counts += 1
      if(interval_counts > 60) {
        $('#modal_rails_5 .modal-body').html(`
          <p>Aucune réponse n'a été obtenue de la caisse.</p>
          <p class='mb-0'>Veuillez vous assurer qu'elle est bien connectée à internet et retentez la génération de votre facture.</p>
        `)  
        clearInterval(this.interval)      
      } else {
        $.get(`/back/remote_service_items/check?uuid=${uuid}`).done(data => {
          if(data.status == 'completed') {
            $('#modal_rails_5 .modal-body').html(`
              <p>La demande de génération de facture a bien été traitée par votre caisse.</p>
              <p class='mb-0'>Les factures seront accessibles d'ici quelques minutes sur votre tableau de bord.</p>
            `)
            clearInterval(this.interval)
          } else if(data.status == 'failed') {
            $('#modal_rails_5 .modal-body').html(`
              <p class='mb-0'>${data.message}</p>
            `)
            clearInterval(this.interval)
          }
        })  
      }
      

    }, 10000)    
  }
}
