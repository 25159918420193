import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--shop-form-external-id"
export default class extends Controller {
  connect() {
    this.externalName = $(this.element).data().externalName
    this.init_select()
  }

  init_shop() {
    $('#loading_app').addClass('d-flex')
    this.toggle_btn(true)
    $.get('/back/api_modules/fetch_external_shops', (data) => {
      this.shops = data.data
      console.log(data.data)
      this.toggle_btn(false)
      $('#loading_app').removeClass('d-flex')
      this.modal_shops()
    })
  }

  init_select() {
    $(document).on('click', '.external_shop_modal p', (element) => {
      this.externalId = $(element.currentTarget).parent().data().id
      $('.custommodalception').hide()
      $('.modal-content').removeClass('closing-alert')
      $('.custommodalception .btn.btn-primary').show()
      this.setExternalId()
    })
  }

  toggle_btn(loading) {
    $(this.element).find('.btn').toggleClass('btn-loading', loading)
    $(this.element).find('.btn').prop('disabled', loading)
    $(this.element).find('.position-absolute').toggle(loading)
  }

  setExternalId() {
    $('#shop_externalId').val(this.externalId)
    $('#shop_externalId').parents('.shop_externalId').next().find('.externalId_display').text(this.externalId)
    $('#shop_externalId').parents('.shop_externalId').next().addClass('has_externalId')  
    $('.external_shop_modal p').unbind('click') 
  }

  removeId() {
    $('#shop_externalId').val('')
    $('#shop_externalId').parents('.shop_externalId').next().eq(0).find('.externalId_display').text('')
    $('#shop_externalId').parents('.shop_externalId').next().removeClass('has_externalId')
  }

  modal_shops() {
    $('.modal-content').addClass('closing-alert')
    $('.modalception-title').text(`Choix de la boutique ${this.externalName}`)
    $('.modalception-body').html(`
      <div class="external_shop_modal mb-2">
        ${this.external_shops()}
      </div>
    `)
    $('.custommodalception .btn.btn-primary').hide()
    $('.custommodalception').slideDown(400)
    $('.external_product_modal').css('height', $(window).height() - 229)
  }

  external_shops() {
    let shops = ""
    $.each(this.shops, (id, object) => {
      shops += `
        <div class='py-1 mb-0 clickable' data-name='${latinize(object.name.toLowerCase())}' data-id="${object.id}">
          <p class='mb-0'>${object.name}</p>
        </div>
      `
    })
    return shops
  }

}
