import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--statistics--commercial-performances-show"
export default class extends Controller {
  static targets = ['dateRangeCreatedAt', 'dateRangeDesiredSignatureDate']
  connect() {
    let datas = $(this.element).data()
    let ranges = {
      "La semaine en cours": [moment(datas.currentWeek.split('-')[0], 'DD/MM/YYYY'), moment(datas.currentWeek.split('-')[1], 'DD/MM/YYYY')],
      "Le mois en cours": [moment(datas.currentMonth.split('-')[0], 'DD/MM/YYYY'), moment(datas.currentMonth.split('-')[1], 'DD/MM/YYYY')],
      "Le mois précédent": [moment(datas.prevMonth.split('-')[0], 'DD/MM/YYYY'), moment(datas.prevMonth.split('-')[1], 'DD/MM/YYYY')],      
      "Le trimestre en cours": [moment(datas.currentQuarter.split('-')[0], 'DD/MM/YYYY'), moment(datas.currentQuarter.split('-')[1], 'DD/MM/YYYY')],
      "L'année en cours": [moment(datas.currentYear.split('-')[0], 'DD/MM/YYYY'), moment(datas.currentYear.split('-')[1], 'DD/MM/YYYY')],
      "Le mois suivant": [moment(datas.nextMonth.split('-')[0], 'DD/MM/YYYY'), moment(datas.nextMonth.split('-')[1], 'DD/MM/YYYY')],
      "Le trimestre suivant": [moment(datas.nextQuarter.split('-')[0], 'DD/MM/YYYY'), moment(datas.nextQuarter.split('-')[1], 'DD/MM/YYYY')]
    }
    $('#commercial_performance_daterange_created_at > div').daterangepicker({
      parentEl: ".filter_card",
      ranges: ranges,
      startDate: $(this.dateRangeCreatedAtTarget).data().startDate.split('/').join('-'),
      endDate: $(this.dateRangeCreatedAtTarget).data().endDate.split('/').join('-')
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $(this.dateRangeCreatedAtTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeCreatedAtTarget).find('span').text(picker)
      }
      $(this.dateRangeCreatedAtTarget).data('startDate', start.format('DD-MM-YYYY'))
      $(this.dateRangeCreatedAtTarget).data('endDate', end.format('DD-MM-YYYY'))
    })
    $('#commercial_performance_daterange_desired_signature_date > div').daterangepicker({
      parentEl: ".filter_card",
      ranges: ranges,
      startDate: $(this.dateRangeDesiredSignatureDateTarget).data().startDate.split('/').join('-'),
      endDate: $(this.dateRangeDesiredSignatureDateTarget).data().endDate.split('/').join('-')
    }, (start, end, picker) => {
      if(picker == "Personnalisé") {
        $(this.dateRangeDesiredSignatureDateTarget).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      } else {
        $(this.dateRangeDesiredSignatureDateTarget).find('span').text(picker)
      }
      $(this.dateRangeDesiredSignatureDateTarget).data('startDate', start.format('DD-MM-YYYY'))
      $(this.dateRangeDesiredSignatureDateTarget).data('endDate', end.format('DD-MM-YYYY'))
    })
  }

  createdAtCheckFilter(e) {
    $('#commercial_performance_daterange_created_at').toggle($(e.currentTarget).prop('checked'))
  }

  desiredSignatureDateCheckFilter(e) {
    $('#commercial_performance_daterange_desired_signature_date').toggle($(e.currentTarget).prop('checked'))
  }

  filter() {
    let params = {
      createdAtStartDate: $(this.dateRangeCreatedAtTarget).data().startDate,
      createdAtEndDate: $(this.dateRangeCreatedAtTarget).data().endDate,
      createdAtCheck: $('#commercial_performance_filter_check_created_at').prop('checked'),
      signatureStartDate: $(this.dateRangeDesiredSignatureDateTarget).data().startDate,
      signatureEndDate: $(this.dateRangeDesiredSignatureDateTarget).data().endDate,
      signatureCheck: $('#commercial_performance_filter_check_desired_signature_date').prop('checked'),
      prospect_inbound_type_ids: $('#commercial_performance_filter_prospect_inbound_type_ids').val().join('-'),
      displaySuspects: $('#commercial_performance_filter_display_suspects').prop('checked')
    }

    if($('#prospect_commercial').val() != '_' && $('#prospect_commercial').val() !== null) {
      params.toporder_employee_id = $('#prospect_commercial').val()
    }

    ajaxTurboStream(`${window.location.pathname}?${new URLSearchParams(params).toString()}`, true)
  }

  toggleStep(e) {
    let container = $(e.currentTarget).parents('.commercial_performance_prospect_commercial_step_group')
    if(container.find('.commercial_performance_prospect_commercial_step:visible').length == 0) {
      container.find('.commercial_performance_prospect_commercial_step').show(200)
      $(e.currentTarget).text("Cacher")
      setTimeout(() => {
        if(container.find('.sorting').length == 0) {
          container.find('table').DataTable({
            "pageLength": 2000,
            'order': [],
            'scrollX': true,
            "caseInsensitive": true
          })          
        }

      }, 500)

    } else {
      container.find('.commercial_performance_prospect_commercial_step').hide()
      $(e.currentTarget).text("Voir le détail")      
    }
  }
}
