import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--external-binding-pick-up-locations-form"
export default class extends Controller {
  connect() {
    $(this.element).find('#external_binding_pick_up_location_externalId').on('select2:select', () => {
      $('#external_binding_pick_up_location_externalName').val($('#external_binding_pick_up_location_externalId option:selected').text())
    })
  }
}
