import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';
import { defaultMomentRanges } from 'shared/default_moment_ranges';
// Connects to data-controller="index-pages--generic-index-page"
export default class extends Controller {
  connect() {    
    this.params = {page: 1, orderBy: $(this.element).data().defaultOrderBy}
    this.loadDateRangePickers()
    this.loadAutocompletes()
    this.filter()
    this.element['controller'] = this
  }

  loadAutocompletes() {
    $.each($(this.element).find('.index-page-filters__input--autocomplete'), (i, input) => {
      if($(input).data().default) {
        this.setAutocompleteValue($(input), $(input).data().default.value, $(input).data().default.label)
      }
      $(input).autocomplete({
        minLength: 3,
        delay: 500,
        source: $(input).data().autocomplete,
        search: ( event, ui ) => {
          $(input).parent().find('.index-page-filters__input--autocomplete__spinner').show()
        },
        response: ( event, ui ) => {
          $(input).parent().find('.index-page-filters__input--autocomplete__spinner').hide()
        },
        select: ( event, ui ) => {
          this.setAutocompleteValue($(input), ui.item.value, ui.item.label)
          return false;
        }
      })
    })
  }

  setAutocompleteValue(input, value, label) {
    input.hide()
    input.val('')
    this.params[input.data().key] = value
    input.parent().find('.index-page-filters__input--autocomplete-mask').addClass('active')
    input.parent().find('.index-page-filters__input--autocomplete-mask span').text(label)
    this.selectAutocompleteCallback(input)
  }

  resetAutocomplete(e) {
    let parent = $(e.currentTarget).parents('.index-page-filters-container__input-container__text').eq(0)
    let input = parent.find('input')
    input.show()
    delete this.params[input.data().key]
    parent.find('.index-page-filters__input--autocomplete-mask').removeClass('active')   
    this.deselectAutocompleteCallback(input) 
  }

  selectAutocompleteCallback(input) {}
  deselectAutocompleteCallback(input) {}

  loadDateRangePickers() {
    this.ranges = defaultMomentRanges(moment)

    $.each($(this.element).find('.index-page-filters__daterangepicker'), (i, element) => {
      let ranges = {}
      let startDate = moment($(element).data().default.split('..')[0].split('/').reverse().join('-'))
      let endDate = moment($(element).data().default.split('..')[1].split('/').reverse().join('-'))
      if(!($(element).data().nullable && $(element).find('span').text("Toutes les dates"))) {
        this.params[`${$(element).data().key}From`] = startDate.startOf('day').unix()
        this.params[`${$(element).data().key}To`] = endDate.endOf('day').unix()   
      }

      $.each($(element).data().collection, (j, range_key) => {
        ranges[this.ranges[range_key].label] = this.ranges[range_key].range
      })
      $(element).find('p').daterangepicker({
        parentEl: '.index-page-filters-container',
        maxDate: moment().add(60, 'days'),
        maxYear: 2030,
        minYear: 2000,
        changeYear: true,
        changeMonth: true,
        showDropdowns: true,
        ranges: ranges,
        startDate: startDate,
        endDate: endDate
      }, (start, end, picker) => {
        if(picker == "Personnalisé") {
          $(element).find('span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
        } else {
          $(element).find('span').text(picker)
        }

        if($(element).data().nullable && picker == "Toutes les dates") {
          delete this.params[`${$(element).data().key}From`]
          delete this.params[`${$(element).data().key}To`]
        } else {
          this.params[`${$(element).data().key}From`] = start.startOf('day').unix()
          this.params[`${$(element).data().key}To`] = end.endOf('day').unix()
        }
      })
    })
  }

  filter() {
    $.each($(this.element).find('.index-page-filters__input').not('.select2-selection'), (i, input) => {
      if($(input).prop('tagName') == 'SELECT') {
        this.handleSelectInput($(input))
      } else if($(input).data().autocomplete.length == 0) {
        if($(input).data().nullable && $(input).val().length == 0) {
          delete this.params[$(input).data().key]
        } else {
          this.params[$(input).data().key] = this.formatValue($(input))
        }
        
      }
    })
    this.params.page = 1
    this.reload()
  }

  sort(orderBy) {
    this.params.orderBy = orderBy
    this.reload()
  }

  reload() {
    ajaxPostTurboStream($(this.element).data().endpoint, this.params)
  }

  changePage(page) {
    this.params.page = page
    this.reload()
  }

  handleSelectInput(input) {
    if(input.val() == '_') {
      if(input.data().fallback) {
        this.params[input.data().key] = input.data().fallback
      } else {
        delete this.params[input.data().key]
      }
    } else {
      this.params[input.data().key] = this.formatValue(input)
    }
  }

  formatValue(input) {
    switch (input.data().paramType) {
      case 'Array':
        return [input.val()]
        break;
      case 'JSON':
        return JSON.parse(input.val())
        break;
      default:
        return input.val()
    }
  }

  exportXlsx() {
    $('.menu_export_xlsx').hide()

    $('#loading_app').removeClass('d-flex')
    initModal()
    $('.modal-header').hide()

    let export_params = this.params
    export_params.shopGroupId = $(this.element).data().exportShopGroupId
    delete export_params.page
    if($(this.element).data().exportMode == 'direct') {
      let array_params = Object.entries(export_params).flatMap(([key, values]) => Array.isArray(values) ? values.map((value) => [key, value]) : [[key, values]])
      let query = new URLSearchParams(array_params).toString()
      let url = `${$(this.element).data().exportEndpoint}?${query}`

      $('.modal-body').html('Votre fichier est en cours de préparation. Cela peut prendre un peu de temps. Vous pourrez fermer cette fenêtre à la fin du téléchargement')
      $('.modal-footer').html(`
        <i class="fas fa-spinner fa-spin" style='font-size: 1.5em; color: #E43B57;'></i>
      `)

      $('.modal-footer').show()
      $('#modal_rails_5').modal({
        show: true,
        backdrop: 'static',
        keyboard: false
      })

      setTimeout(() => {
        if($('.modal-footer').find('.fa-spinner').length > 0) {
          $('.modal-footer').html(`<button class='btn btn-outline-primary' onClick="$('#modal_rails_5').modal('hide')">J'ai bien reçu mon fichier</button>`)
        }
      }, 5000) 
      window.location = url
    } else {
      let query = new URLSearchParams(export_params).toString()
      let url = `${$(this.element).data().exportEndpoint}?${query}`
      $('.modal').modal('show')
      $('.modal-body').html(`
        <p>Le document que vous avez demandé est en cours de génération.</p>
        <p>Vous recevrez un mail quand il sera disponible. Vous pourrez également le retrouver dans la partie <a class="text-toporder" href="/back/export_documents">Mes documents</a>.</p>
        <p class='mb-0'>Il restera disponible pour une durée de 7 jours.</p>
      `)
      $('.modal-footer').html(`
        <p class="btn btn-outline-primary mb-0" data-dismiss="modal">J'ai compris</p>
      `)
      $.get(url)
    }
 

  }
}
